header {
  z-index: 1201;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  background-color: #fff !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.02),
    0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.01) !important;
}

.mainsidebar {
  background-color: #f0f4f4 !important;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2) !important;
  border: none !important;
}

.makeStyles-menuButton-30 {
  margin-right: 36px;
  background-color: #2474fc !important;
  opacity: 1 !important;
}

.MuiListItem-root {
  background-color: #4fbfc1 !important;
  margin-bottom: 5px !important;
  color: #fff !important;
  border-radius: 6px !important;
}

.MuiList-root {
  padding: 10px !important;
}

.MuiListItemIcon-root {
  color: #fff !important;
  min-width: 36px !important;
}

MuiTypography-body1 {
  font-size: 14px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: 0px !important;
}

.MuiListSubheader-sticky {
  top: 0;
  z-index: 1;
  position: sticky;
  background-color: inherit;
  padding-left: 5px !important;
  text-align: left !important;
  color: #2474fc !important;
  font-weight: 600 !important;
}

.logout {
  background-color: #2474fc !important;
}

.MuiTableCell-root {
  display: table-cell;
  padding: 6px 12px !important;
  font-size: 14px !important;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  letter-spacing: 0px !important;
  vertical-align: inherit;
}

.strip {
  background-color: #2474fc;
  margin-bottom: 20px;
  padding: 18px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.04), 0 10px 10px rgba(0, 0, 0, 0.12);
  border-radius: 7px;
}

.MuiGrid-grid-sm-4 {
  color: #fff !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #2474fc !important;
}

.MuiButton-containedPrimary {
  color: #fff;
  background-color: #2474fc !important;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
}

.MuiButton-outlinedPrimary {
  color: #4dbdc1 !important;
  border: 1px solid #4dbdc1 !important;
  border-right-color: 1px solid #4dbdc1 !important;
}

/* Backdrop */
.loader {
  position: absolute;
  margin-top: 300px;
  margin-left: 50%;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  justify-content: center;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

/* Define blinking animation */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blinking-text {
  animation: blink 1s infinite;
  font-weight: bold;
  font-size: 16px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
