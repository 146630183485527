.project {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .table-container {
    width: 45%;
    margin: 0 auto; 
    /* padding: 20px 0; */
  }
  
  .table {
    border: 2px solid #000;
    border-collapse: collapse;
    background-color: #f1f5f5e9;
  }
  
  .cell {
    border: 2px solid #000;
    padding: 8px;
  }
  .header-cell {
    border: 2px solid #000;
    padding: 8px; 
  }
  .team-members-scroll {
    max-height: 150px; 
    overflow-y: auto;
  }
  .bold-label {
    font-weight: bold;
}