.icon-small {
    font-size: 10px;
  }
  
  .list-item {
    padding: 4px 8px;
  }
  
  .list-item-text {
    font-size: 14px;
  }

  .custom-divider {
    margin: 4px 0;
  }
  