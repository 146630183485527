.project-details {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -400px;
  }

.boldText {
  font-weight: bold;
}


.MuiFormControl-marginNormal {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.buttView{
  min-width: 100px;
  line-height: 3;
}

